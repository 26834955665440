import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MaterialModule } from 'src/3rd-party';
import { ServicesModule } from 'src/services';

import { AppComponent } from './app/app.component';
import { ToolbarComponent, SidenavService } from './toolbar/toolbar.component';
import { HomePageComponent } from './home-page/home-page.component';
import { SystemTemplateComponent } from './system-template/system-template.component';
import { HfeComponentsModule } from '../hfe-components';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ServicesModule,
        HfeComponentsModule,
        RouterModule.forChild([]),
    ],
    declarations: [
        AppComponent,
        ToolbarComponent,
        HomePageComponent,
        SystemTemplateComponent
    ],
    exports: [
        AppComponent,
        ToolbarComponent,
        HomePageComponent,
        SystemTemplateComponent
    ],
    providers: [
        SidenavService
    ]
})
export class AppComponentsModule {
}