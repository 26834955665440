import { Component } from '@angular/core';
import { ZappAppBoard, ZappAppBoardStore } from 'src/component-store';
import { ConfigurationService } from 'src/services';

@Component({
    selector: 'zs-home-page',
    templateUrl: 'home-page.component.html',
    styleUrls: ['home-page.component.scss']
})
export class HomePageComponent {

    public mainBoard: ZappAppBoard;
    public menuBoard: ZappAppBoard;
    public footerBoard: ZappAppBoard;

    constructor(public configurationService: ConfigurationService,
        private zappAppBoardStore: ZappAppBoardStore) {
        if (configurationService.clientConfigurationLoaded) {
            this.loadConfigurationBoards();
        } else {
            const _sub = configurationService.clientConfigurationLoaded$.subscribe(loaded => {
                if (loaded) {
                    _sub?.unsubscribe();
                    this.loadConfigurationBoards();
                }
            })
        }
    };

    private loadConfigurationBoards(): void {
        this.menuBoard = this.loadConfigurationBoard('menu_board');
        this.mainBoard = this.loadConfigurationBoard('main_board');
        this.footerBoard = this.loadConfigurationBoard('footer_board');
    }

    private loadConfigurationBoard(configurationBoard: string): ZappAppBoard {
        const appPlusBoardString = this.configurationService.getConfigurationItem(configurationBoard) as string;
        if (appPlusBoardString) {
            const appAndBoard = appPlusBoardString.split('_');
            if (appAndBoard.length === 2) {
                return this.zappAppBoardStore.getZappAppBoardByIdPlusAppId(appAndBoard[0], appAndBoard[1]);
            }
        }

        return null;
    }
}
