<!-- Loading Spinner -->
<mat-card class="zapp-transfer-loading" [hidden]="!showLoadingSpinner">
    <mat-spinner mode="indeterminate" diameter="200">
    </mat-spinner>
</mat-card>

<!-- Zapp Export -->
<mat-card appearance="outlined">
    <mat-card-header>
        <mat-card-title>Zapp Export</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form>
            <section>
                <!-- App Filter -->
                <mat-form-field appearance="outline">
                    <mat-label>Filter (AppId or AppId/BoardId)</mat-label>
                    <input matInput [(ngModel)]="zappExportAppFilter" [ngModelOptions]="{standalone: true}"
                        maxlength="40" size="40" />
                </mat-form-field>
            </section>
        </form>

        <!-- Export Applications -->
        <section>
            <div>
                <button mat-flat-button (click)="exportApplications()">
                    Export Applications
                </button>
            </div>
        </section>
    </mat-card-content>
</mat-card>

<!-- Zapp Import -->
<mat-card appearance="outlined">
    <mat-card-header>
        <mat-card-title>Zapp Import</mat-card-title>
    </mat-card-header>
    <mat-card-content>

        <form>
            <section>
                <!-- App Filter -->
                <mat-form-field appearance="outline">
                    <mat-label>Filter (AppId or AppId/BoardId)</mat-label>
                    <input matInput [(ngModel)]="zappImportAppFilter" [ngModelOptions]="{standalone: true}"
                        maxlength="40" size="40" />
                </mat-form-field>
            </section>
        </form>

        <section>
            <div>
                <input #fileInput hidden="true" type="file" onclick="this.value=null"
                    (change)="importApplications($event)" />
                <button mat-flat-button (click)="fileInput.click()">Select Zapp Import File</button>
            </div>
        </section>

    </mat-card-content>
</mat-card>

<!-- Zapp Transfer From Existing -->
<mat-card appearance="outlined">
    <mat-card-header>
        <mat-card-title>Zapp Transfer From Existing</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form>
            <section>
                <!-- App Filter -->
                <mat-form-field appearance="outline">
                    <mat-label>Filter (AppId or AppId/BoardId)</mat-label>
                    <input matInput [(ngModel)]="zappTransferAppFilter" [ngModelOptions]="{standalone: true}" />
                </mat-form-field>
            </section>

            <section>
                <mat-label>Source Connection Info</mat-label>
            </section>

            <section>
                <!-- Url -->
                <mat-form-field appearance="outline">
                    <mat-label>Url</mat-label>
                    <input matInput [(ngModel)]="zappTransferUrl" [ngModelOptions]="{standalone: true}" />
                </mat-form-field>

                <!-- User Name -->
                <mat-form-field appearance="outline">
                    <mat-label>User Name</mat-label>
                    <input matInput [(ngModel)]="zappTransferUserName" [ngModelOptions]="{standalone: true}"
                        maxlength="40" size="40" />
                </mat-form-field>

                <!-- Facility -->
                <mat-form-field appearance="outline">
                    <mat-label>Facility</mat-label>
                    <input matInput [(ngModel)]="zappTransferFacility" [ngModelOptions]="{standalone: true}"
                        maxlength="40" size="40" />
                </mat-form-field>
                <!-- Password -->
                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput [(ngModel)]="zappTransferPassword" [ngModelOptions]="{standalone: true}"
                        maxlength="40" size="40" type="password" />
                </mat-form-field>
            </section>
        </form>

        <!-- Transfer Boards -->
        <section>
            <div>
                <button mat-flat-button (click)="transferBoards()">
                    Transfer Boards
                </button>
            </div>
        </section>
    </mat-card-content>
</mat-card>

<!-- Doc Configuration Import -->
<mat-card appearance="outlined">
    <mat-card-header>
        <mat-card-title>Doc Configuration Import</mat-card-title>
    </mat-card-header>
    <mat-card-content>

        <section style="margin-bottom: 10px;">
            <div>
                <input #fileInput hidden="true" type="file" onclick="this.value=null" (change)="importPalettes($event)"
                    accept="xfr/*.xfr" />
                <button mat-flat-button (click)="fileInput.click()">Select Palette File</button>
            </div>
        </section>

        <section>
            <div>
                <input #fileInput hidden="true" type="file" onclick="this.value=null" (change)="importNodeKinds($event)"
                    accept="xfr/*.xfr" />
                <button mat-flat-button (click)="fileInput.click()">Select NodeKinds File</button>
            </div>
        </section>

    </mat-card-content>
</mat-card>

<!-- Compare Published Versions -->
<mat-card appearance="outlined">
    <mat-card-header>
        <mat-card-title>Compare Published Versions</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form>

            <section>
                <mat-label>Version Info</mat-label>
            </section>

            <section>
                <!-- Publish Target -->
                <mat-form-field appearance="outline">
                    <mat-label>Publish Target</mat-label>
                    <input matInput [(ngModel)]="comparePublishTarget" [ngModelOptions]="{standalone: true}"
                        maxlength="40" size="40" />
                </mat-form-field>
            </section>

            <section>
                <!-- compareVersion1 -->
                <mat-form-field appearance="outline">
                    <mat-label>Version 1</mat-label>
                    <input matInput [(ngModel)]="compareVersion1" [ngModelOptions]="{standalone: true}" maxlength="40"
                        size="40" />
                </mat-form-field>

                <button mat-flat-button (click)="buildZipExport()" style="margin-left: 10px;">
                    Build Zip Export
                </button>
            </section>

            <section>
                <!-- compareVersion2 -->
                <mat-form-field appearance="outline">
                    <mat-label>Version 2</mat-label>
                    <input matInput [(ngModel)]="compareVersion2" [ngModelOptions]="{standalone: true}" maxlength="40"
                        size="40" />
                </mat-form-field>
            </section>
        </form>

        <section>
            <!-- Compare Versions -->
            <button mat-flat-button (click)="compareVersions()">
                Compare Versions
            </button>

            <!-- Show Versions Log -->
            <button mat-flat-button (click)="showVersionsLog()" style="margin-left: 10px;">
                Show Versions Log
            </button>

        </section>
    </mat-card-content>
</mat-card>