import { APP_BASE_HREF } from "@angular/common";
import { Inject, Injectable } from "@angular/core";

import { EditorOptions, FrmObject, LinkToEntityQueryParams } from "@dicorp/html-ffe";
import { ZappsmithWebService, APP_ENVIRONMENT_OPTIONS, AppEnvironmentOptions, AppEnvironment, ZappsmithBaseParmDict } from "@dicorp/zappsmith-ngx-core";

import { FfeEditorOptions, PortalFunctions } from "src/common";
import { ZappAppBoardStore } from "src/component-store";

import { SessionService, ConfigurationService, AlertService, DatasetUtilsService, GeneralDatasetService, ReportService, AlertType } from "src/services";

@Injectable({
    providedIn: 'root'
})
export class HfeEditorOptionsService {

    constructor(
        private sessionService: SessionService,
        private configurationService: ConfigurationService,
        private alertService: AlertService,
        private datasetUtilsService: DatasetUtilsService,
        private generalDatasetService: GeneralDatasetService,
        private zappsmithWebService: ZappsmithWebService,
        private zappAppBoardStore: ZappAppBoardStore,
        private reportService: ReportService,
        @Inject(APP_ENVIRONMENT_OPTIONS) private appEnvironmentOptions: AppEnvironmentOptions,
        @Inject(APP_BASE_HREF) private appBaseHref: string) {
    }

    setDefaultEditorOptions(editorOptions: EditorOptions, ffeOptions: FfeEditorOptions, include3rdPartyOptions: boolean): void {
        if (include3rdPartyOptions) {
            this.setup3rdPartyFfeEditorOptions(editorOptions);
        }

        this.setupDefaultBindings(editorOptions, ffeOptions);
    }

    private setup3rdPartyFfeEditorOptions(editorOptions: EditorOptions): void {
        // tinyMCE is hardcoded
        if (this.appEnvironmentOptions.appEnvironment !== AppEnvironment.local) {
            editorOptions.googleMapsApiKey = 'AIzaSyC4yyK-cCZgcwQ7fcDQMm1WUibZcWOCBvY';
            editorOptions.tiny_mce_path = this.appBaseHref + 'tinymce/';
            // editorOptions.tiny_mce_path = this.appEnvironmentOptions.baseUrl ?
            //     this.appEnvironmentOptions.baseUrl : ''
            //     + '/portal/lib/tinymce/';
        } else {
            editorOptions.googleMapsApiKey = 'AIzaSyAtkvQkj5xNa39xzhQfkYZWCQHD36wB9k0';
            editorOptions.tiny_mce_path = this.appBaseHref + 'tinymce/';
        }

        const tmce = this.configurationService.getConfigurationItem("tiny_mce_fonts");
        if (tmce) {
            editorOptions.tiny_mce_fonts = JSON.parse(tmce);
        } else {
            editorOptions.tiny_mce_fonts = default_tiny_mce_fonts;
        }
    }

    private setupDefaultBindings(editorOptions: EditorOptions, ffeOptions: FfeEditorOptions): void {
        // Code Methods
        editorOptions.diGetCodeValue = (name: string, code: string) => {
            return this.datasetUtilsService.getCodeValue(name, code);
        }

        editorOptions.diGetCodeOptions = (name: string, code: string, parent: any) => {
            return this.datasetUtilsService.getCodeOptions(name, code, parent);
        }

        // Entity Methods
        editorOptions.diHasEntityPermission = (entity_name: string, operation: string) => {
            return this.hasEntityPermission(ffeOptions, entity_name, operation);
        }

        editorOptions.diGetEntity = (entity_name: string, key: string) => {
            return this.datasetUtilsService.getEntity(entity_name, ffeOptions?.entityMap, key);
        }

        editorOptions.diGetEntities = (entity_name: string) => {
            return this.datasetUtilsService.getEntities(entity_name, ffeOptions?.entityMap);
        };
        editorOptions.diGetEntitiesQuery = (entity_name: string, params: LinkToEntityQueryParams) => {
            return this.datasetUtilsService.getEntitiesQuery(entity_name, ffeOptions?.entityMap, params);
        };

        // Reporting Methods
        editorOptions.diRunQuery = (frm_object: FrmObject, record: any) => {
            return this.runQuery(frm_object, record);
        }

        editorOptions.diExportQuery = (frm_object: FrmObject, record: any, format: 'csv' | 'excel') => {
            return this.runExportQuery(frm_object, record, format);
        }

        // Attachment Methods
        editorOptions.diUpdateFile = (file: File, record: any, context: FrmObject) => {
            return this.upload_file(file, record, context, ffeOptions);
        }
        editorOptions.diViewFile = (file: File, record: any, context: FrmObject) => {
            return this.view_file(file, record, context, ffeOptions);
        }
        editorOptions.diDeleteFile = (file: File, record: any, context: FrmObject) => {
            return this.delete_file(file, record, context, ffeOptions);
        }
        editorOptions.diGetFileUrl = (file: File, record: any, context: FrmObject) => {
            return this.get_file_url(file, record, context, ffeOptions);
        }
    }

    hasEntityPermission(ffeOptions: FfeEditorOptions, entityName: string, operation: string, alertOnError: boolean = false): boolean {
        const mapping = ffeOptions?.entityMap[entityName];
        const whichBoard = mapping && mapping['object_name'] ? mapping['object_name'] : entityName;
        const board = this.zappAppBoardStore.getZappAppBoard(whichBoard);
        let permissionBaseName = (board && board._extra) ? board._extra.permissionBaseName : null;
        if (!!(ffeOptions?.entityMap) && !!(ffeOptions?.entityMap[entityName])) {
            const mapping = ffeOptions?.entityMap[entityName];
            if (!!mapping['permission_base_name']) {
                permissionBaseName = mapping['permission_base_name'];
            }
        }

        if (!permissionBaseName) {
            this.alertService.addAlert({
                title: 'Error',
                message: 'permissionBaseName name not found for: ' + entityName,
                type: AlertType.error
            });
            return false;
        }

        const permission = permissionBaseName + operation + "Level";
        const retval = this.sessionService.hasPermission(permission);
        if (!retval && alertOnError) {
            this.alertService.addAlert({
                title: 'No permissions',
                message: "You do not have permission to " + operation + " this",
                type: AlertType.warning
            });
        }
        return retval;
        //'parentKeyField': parentKeyField
    }

    private runQuery(frmObject: any, record: any): Promise<any> {
        let parameter_dict = {}
        if (!!frmObject.parameter_dict_path) {
            parameter_dict = PortalFunctions.deep_value(record, frmObject.parameter_dict_path)
        }

        return new Promise<any>((resolve, reject) => {
            this.reportService.raw_query(null, frmObject.query_name, parameter_dict).then(
                results => {
                    resolve(results)
                }, results => {
                    reject(results)
                }
            );
        });
    };

    private runExportQuery(frmObject: any, record: any, format: string) {
        let parameter_dict = {}
        if (!!frmObject.parameter_dict_path) {
            parameter_dict = PortalFunctions.deep_value(record, frmObject.parameter_dict_path)
        }
        return new Promise<any>((resolve, reject) => {
            this.reportService.query(null, frmObject.query_name, parameter_dict, format).then(
                results => {
                    resolve(results) // full response
                },
                results => {
                    reject(results)
                });
        });
    }

    private get_file_url(file: any, record: any, context: any, ffeOptions: FfeEditorOptions): Promise<string> {
        const filename = file['@name'];
        const key = file['#value'];
        const doc = { '_id': record._id, 'primary_facility_key': record.primary_facility_key };
        const dataUrl = ffeOptions.baseObject + "/attachment?key=" + key + "&document=" + JSON.stringify(doc) + "&filename=" + filename;
        return Promise.resolve(dataUrl);
    };

    private view_file(file: any, record: any, context: any, ffeOptions: FfeEditorOptions): Promise<void> {
        const filename = file['@name'];
        const key = file['#value'];
        const isPdf = !PortalFunctions.isTrueValue(this.configurationService.getConfigurationItem('forcePdfDownload', false)) && filename && filename.endsWith('pdf')
        const doc = { '_id': record._id, 'primary_facility_key': record.primary_facility_key };
        const dataUrl = ffeOptions.baseObject + "/attachment?key=" + key + "&document=" + JSON.stringify(doc) + "&filename=" + filename;
        const hiddenElement = document.createElement('a');
        hiddenElement.setAttribute("type", "hidden");
        hiddenElement.href = dataUrl;
        if (!isPdf) {
            hiddenElement.download = filename;
        } else {
            hiddenElement.target = "_blank";
        }
        document.body.appendChild(hiddenElement);
        hiddenElement.click();
        hiddenElement.remove();

        return Promise.resolve(context);
    };

    private upload_file(file: File, record: any, context: any, ffeOptions: FfeEditorOptions): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const attachmentUploadUrl = ffeOptions.baseObject + '/attachment';

            const params: ZappsmithBaseParmDict = {
                filename: file?.name,
                file: file,
                document: JSON.stringify(record ? record : {})
            };

            this.zappsmithWebService.post(attachmentUploadUrl, params).then(
                result => {
                    let filename = file.name;
                    filename = filename.replace(/\.heic$/i, '.png');
                    result['filename'] = filename;
                    result['context'] = context;
                    result['#value'] = result['key']; // copy for CB ease
                    console.info('file uploaded. Response: ' + JSON.stringify(result));
                    resolve(result);
                },
                result => {
                    console.info('error status: ' + result?.status);
                    reject('Error ' + result?.status)
                }
            );
        });
    };

    private delete_file(file: any, record: any, context: any, ffeOptions: FfeEditorOptions): Promise<any> {
        //const key = file.key;
        if (!ffeOptions.should_delete_attachments) {
            return Promise.resolve(context);
        } else {
            return new Promise<any>((resolve, reject) => {
                const key = file['#value'];
                const doc = { '_id': record._id, 'primary_facility_key': record.primary_facility_key };
                //const doc = {};
                this.generalDatasetService.deleteAttachment(ffeOptions.baseObject, key, doc).then(
                    result => {
                        resolve(context);
                    },
                    result => {
                        resolve(context); // fire the rule anyway
                        this.alertService.addAlert({
                            title: 'Error',
                            message: "Could not delete attachment",
                            type: AlertType.error
                        });
                    });

            });
        }
    };
}

const default_tiny_mce_fonts = [
    {
        "name": "Libre Baskerville",
        "url": "https://fonts.googleapis.com/css?family=Libre+Baskerville",
        "font_family": "Libre Baskerville"
    }, {
        "name": "Montserrat",
        "url": "https://fonts.googleapis.com/css?family=Montserrat",
        "font_family": "Montserrat"
    }, {
        "name": "Open Sans",
        "url": "https://fonts.googleapis.com/css?family=Open+Sans",
        "font_family": "Open Sans"
    }, {
        "name": "PT Sans Narrow",
        "url": "https://fonts.googleapis.com/css?family=PT+Sans+Narrow",
        "font_family": "PT Sans Narrow"
    }, {
        "name": "Source Serif Pro",
        "url": "https://fonts.googleapis.com/css?family=Source+Serif+Pro",
        "font_family": "Source Serif Pro"
    }, {
        "name": "Cairo",
        "url": "https://fonts.googleapis.com/css?family=Cairo",
        "font_family": "Cairo"
    }, {
        "name": "Merriweather Sans",
        "url": "https://fonts.googleapis.com/css?family=Merriweather+Sans",
        "font_family": "Merriweather Sans"
    }, {
        "name": "Archivo",
        "url": "https://fonts.googleapis.com/css?family=Archivo",
        "font_family": "Archivo"
    }, {
        "name": "Gideon Roman",
        "url": "https://fonts.googleapis.com/css?family=Gideon+Roman",
        "font_family": "Gideon Roman"
    }
]